const fields = {
  'volume': {
    key: 'volume',
    heading: 'Volume',
    name: 'Volume',
    description: '<h4>Volume</h4><p>The average number of searches per month over the past 12 months.</p>',
    type: 'count'
  },
  'iaat_legacy': {
    key: 'iaat_legacy',
    heading: 'IAAT (Legacy)',
    name: 'IAAT (Legacy)',
    description: '<h4>In Anchor And Title (Legacy)</h4><p>A count of webpages on which the keyword appears in both the title tag and the text of a backlink.</p>',
    type: 'count',
  },
  'kei': {
    key: 'kei',
    heading: 'KEI',
    name: 'KEI',
    notAvailableFor: ['amazon', 'youtube', 'ebay'],
    notAvailableTip: 'Google only',
    description: `<h4>Keyword Effectiveness Index</h4><p>Prioritise the keywords to target first. KEI goes up when the keyword's popularity increases and down when there's more competiton.</p>`,
    type: 'count'
  },
  'competition': {
    key: 'competition',
    heading: 'Competition',
    name: 'Competition',
    description: '<h4>Competition</h4><p>On a scale of 1 to 100, how much organic competition there is for a keyword.</p>',
    type: 'percentage'
  },
  'cmp_legacy': {
    key: 'cmp_legacy',
    heading: 'PPC Comp. (Legacy)',
    name: 'PPC Comp. (Legacy)',
    description: '<h4>PPC Competition (Legacy)</h4><p>The higher this number, the more PPC competition for the keyword.</p>',
    notAvailableFor: ['amazon', 'youtube', 'ebay'],
    notAvailableTip: 'Google only',
    type: 'count'
  },
  'cpc': {
    key: 'cpc',
    heading: 'CPC',
    name: 'CPC',
    description: '<h4>Cost-Per-Click</h4><p>The average PPC bid for this keyword.</p>',
    notAvailableFor: ['amazon', 'youtube', 'ebay'],
    notAvailableTip: 'Google only',
    type: 'currency'
  },
  'intent': {
    key: 'intent',
    heading: 'Intent',
    name: 'Intent',
    description: '<h4>Intent</h4><p>How likely a click on this keyword is to result in an action such as a sale or sign up.</p>',
    notAvailableFor: ['amazon', 'youtube', 'ebay'],
    notAvailableTip: 'Google only',
    type: 'count'
  },
  'intent_legacy': {
    key: 'intent_legacy',
    heading: 'Intent (Legacy)',
    name: 'Intent',
    description: '<h4>Intent (Legacy)</h4><p>How commercial a keyword is considered.</p>',
    notAvailableFor: ['amazon', 'youtube', 'ebay'],
    notAvailableTip: 'Google only',
    calculated: true,
    type: 'custom'
  },
  'pct_mobile_searches': {
    key: 'pct_mobile_searches',
    heading: 'Mobile %',
    name: 'Mobile %',
    description: '<h4>Mobile %</h4><p>The percentage of searches for this keyword that were performed on mobile.</p>',
    notAvailableFor: ['amazon', 'youtube', 'ebay'],
    notAvailableTip: 'Google only',
    type: 'percentage_decimal'
  },
  'pct_desktop_searches': {
    key: 'pct_desktop_searches',
    heading: 'Desktop %',
    name: 'Desktop %',
    description: '<h4>Desktop %</h4><p>The percentage of searches for this keyword that were performed on desktop.</p>',
    notAvailableFor: ['amazon', 'youtube', 'ebay'],
    notAvailableTip: 'Google only',
    type: 'percentage_decimal'
  },
  'pct_organic_clicks': {
    key: 'pct_organic_clicks',
    heading: 'Organic Clicks',
    name: 'Organic Clicks',
    description: '<h4>Organic Click %</h4><p>The percentage of clicks for this keyword that were for organic search results.</p>',
    notAvailableFor: ['amazon', 'youtube', 'ebay'],
    notAvailableTip: 'Google only',
    type: 'percentage_decimal'
  },
  'pct_paid_clicks': {
    key: 'pct_paid_clicks',
    heading: 'Paid Clicks',
    name: 'Paid Clicks',
    description: '<h4>Paid Click %</h4><p>The percentage of clicks for this keyword that were for paid search results.</p>',
    notAvailableFor: ['amazon', 'youtube', 'ebay'],
    notAvailableTip: 'Google only',
    type: 'percentage_decimal'
  },
  'pct_searches_no_clicks': {
    key: 'pct_searches_no_clicks',
    heading: 'No Click Searches',
    name: 'No Click Searches',
    description: '<h4>No Click Searches %</h4><p>The percentage of searches for this keyword where no result was clicked on.</p>',
    notAvailableFor: ['amazon', 'youtube', 'ebay'],
    notAvailableTip: 'Google only',
    type: 'percentage_decimal'
  },
  'rank': {
    key: 'rank',
    heading: 'Rank',
    name: 'Rank',
    description: '<h4>Primary Rank</h4><p>The highest position at which this keyword ranks for this domain.</p>',
    notAvailableFor: ['amazon', 'youtube', 'ebay'],
    notAvailableTip: 'Google only',
    type: 'count'
  },
  'rank_delta': {
    key: 'rank_delta',
    heading: 'Rank Change',
    name: 'Rank Change',
    description: '<h4>Primary Rank Change</h4><p>The change in the \'rank\' metric compared to last week.</p>',
    notAvailableFor: ['amazon', 'youtube', 'ebay'],
    notAvailableTip: 'Google only',
    type: 'change'
  },
  'clicks': {
    key: 'clicks',
    heading: 'Clicks (GSC)',
    name: 'Clicks (GSC)',
    description: '<h4>Clicks</h4><p>The number of times a result for your site was clicked from the search results in the last month.</p>',
    notAvailableFor: ['amazon', 'youtube', 'ebay'],
    notAvailableTip: 'Google only',
    type: 'count'
  },
  'clicks_domain': {
    key: 'clicks_domain',
    heading: 'Clicks',
    name: 'Clicks',
    description: '<h4>Clicks</h4><p>The number of clicks from this keyword for the highest ranking result.</p>',
    notAvailableFor: ['amazon', 'youtube', 'ebay'],
    notAvailableTip: 'Google only',
    type: 'count'
  },
  'clicks_change_domain': {
    key: 'clicks_change_domain',
    heading: 'Clicks Change',
    name: 'Clicks Change',
    description: '<h4>Clicks Change</h4><p>The change in the \'clicks\' metric compared to last month.</p>',
    notAvailableFor: ['amazon', 'youtube', 'ebay'],
    notAvailableTip: 'Google only',
    type: 'change'
  },
  'impressions': {
    key: 'impressions',
    heading: 'Impressions (GSC)',
    name: 'Impressions (GSC)',
    description: '<h4>Impressions</h4><p>The number of times a result for your site was shown for this keyword search in the last month.</p>',
    notAvailableFor: ['amazon', 'youtube', 'ebay'],
    notAvailableTip: 'Google only',
    type: 'count'
  },
  'ctr': {
    key: 'ctr',
    heading: 'CTR (GSC)',
    name: 'CTR (GSC)',
    description: '<h4>CTR</h4><p>The click rate to your site as a percentage of Impressions over the last month.</p>',
    notAvailableFor: ['amazon', 'youtube', 'ebay'],
    notAvailableTip: 'Google only',
    type: 'percentage_decimal'
  },
  'position': {
    key: 'position',
    heading: 'Position (GSC)',
    name: 'Position (GSC)',
    description: '<h4>Position</h4><p>Your average ranking for this keyword over the last month.</p>',
    notAvailableFor: ['amazon', 'youtube', 'ebay'],
    notAvailableTip: 'Google only',
    type: 'rank'
  },
  'RefDomains': {
    key: 'RefDomains',
    name: 'Ref. Domains',
    shortName: 'Ref. Doms',
    heading: 'RDs',
    description: '<h4>Referring Domains</h4><p>The number of domains which link to the result.</p>',
    type: 'count'
  },
  'ExtBackLinks': {
    key: 'ExtBackLinks',
    name: 'Backlinks',
    heading: 'BLs',
    description: '<h4>Backlinks</h4><p>The number of individual external backlinks to the result.</p>',
    type: 'count'
  },
  'CitationFlow': {
    key: 'CitationFlow',
    heading: 'CF',
    name: 'Citation Flow',
    shortName: 'Cit. Flow',
    description: '<h4>Citation Flow</h4><p>A Majestic metric giving a score out of 100 based on the number and strength of links.</p>',
    type: 'percentage'
  },
  'PageSpeed': {
    key: 'PageSpeed',
    heading: 'Page speed',
    name: 'Page Speed',
    description: '<h4>Page Speed</h4><p>A measure of how quickly the page loads, which in turn affects search engine ranking.</p>',
    type: 'percentage'
  },
  'TrustFlow': {
    key: 'TrustFlow',
    heading: 'TF',
    name: 'Trust Flow',
    description: '<h4>Trust Flow</h4><p>A Majestic metric giving a score out of 100 based on the proximity, through links, to trusted domains.</p>',
    type: 'percentage'
  },
  'Difficulty': {
    key: 'Difficulty',
    heading: 'Diff.',
    description: '<h4>Difficulty</h4><p>The difficulty of beating this result, calculated based on the result\'s backlinks, position and trust flow.</p>',
    type: 'score'
  },
  'OverallScore': {
    key: 'OverallScore',
    heading: 'Diff.',
    name: 'Difficulty',
    description: '<h4>Difficulty</h4><p>The difficulty of beating this result, calculated based on the result\'s page speed, optimisation, citation flow and trust flow.</p>',
    type: 'percentage'
  },
  'Optimisation': {
    key: 'Optimisation',
    heading: 'Opt.',
    name: 'Optimisation',
    description: '<h4>Optimisation</h4><p>A score given by Google based on how many core SEO criteria the page fulfils.</p>',
    type: 'score'
  },
  'score': {
    key: 'score',
    heading: 'Score.',
    description: '<h4>Score</h4><p>The higher this number, the more relevant the keyword is on the page.</p>',
    type: 'score'
  },
  'ranking': {
    key: 'ranking',
    heading: 'Ranking',
    description: '<h4>Ranking</h4><p>What position in Google your site appears at for searchs for this keyword.</p>',
    type: 'rank'
  },
  'rank_history': {
    key: 'rank_history',
    heading: 'Rank History',
    description: '<h4>Rank history</h4>This metric updates as we track your website rank each week, starting when you add a keyword to a ranking list',
    type: 'json'
  },
  'opportunity': {
    key: 'opportunity',
    heading: 'Opportunity',
    description: '<h4>Opportunity</h4><p>Our calculation of how high an opportunity there is for your site to rank better for this keyword.</p>',
    type: 'score'
  },
  'ranking_url': {
    key: 'ranking_url',
    heading: 'Ranking URL',
    description: '<h4>Ranking URL</h4><p>The URL that this keyword ranks for at the position specified.</p>',
    type: 'string'
  }
};

const {
  volume,
  iaat_legacy,
  kei,
  competition,
  cpc,
  intent,
  cmp_legacy,
  intent_legacy,
  pct_mobile_searches,
  pct_desktop_searches,
  pct_organic_clicks,
  pct_paid_clicks,
  pct_searches_no_clicks,
  clicks,
  impressions,
  ctr,
  position,
  score,
  ranking,
  rank_history,
  opportunity,
  RefDomains,
  ExtBackLinks,
  CitationFlow,
  PageSpeed,
  TrustFlow,
  Difficulty,
  OverallScore,
  Optimisation,
  clicks_domain,
  clicks_change_domain,
  rank,
  rank_delta,
  ranking_url,
  total_monthly_clicks
} = fields;

const mainSearchFields = [ volume, competition, kei, cpc, intent, pct_mobile_searches, pct_desktop_searches, pct_organic_clicks, pct_paid_clicks, pct_searches_no_clicks];
const domainFields = [ clicks_domain, clicks_change_domain, volume, rank, rank_delta, total_monthly_clicks, competition, kei, pct_searches_no_clicks ];
const rankingFields = [ rank, rank_delta, ranking_url, rank_history ];
const gscFields = [ clicks, impressions, ctr, position ];
const searchFields = [...mainSearchFields, ...gscFields];
const legacyFields = [ cmp_legacy, iaat_legacy ];
const listFields = [...mainSearchFields, clicks_domain, clicks_change_domain, rank, rank_delta, ...gscFields, ...legacyFields];
const serpsFields = [ RefDomains, ExtBackLinks, CitationFlow, PageSpeed, TrustFlow, Difficulty, OverallScore, Optimisation];

const getField = key => fields[key];

const getFields = keys => keys.map(key => fields[key]);

export {
  getField,
  getFields,
  mainSearchFields,
  gscFields,
  searchFields,
  domainFields,
  listFields,
  legacyFields,
  serpsFields,
  rankingFields
}
