import React from 'react'
import Sparkline from './Sparkline';
import moment from "moment/moment.js";
import { v4 as uuidv4 } from 'uuid';

export default function RankHistoryMetric({data = {}}) {

  let xPoints = [];

  data = data || {};

  data = Object.entries(data).sort((a, b) => a[0] - b[0]).map(([timestamp, rank]) => {
    rank = parseInt(rank);
    rank = (rank === -1) ? 0 : (100 - rank);
    xPoints.push(timestamp);
    return { count: rank };
  });

  data = [...Array(Math.max(0, 11 - data.length)).fill({count: 0}), ...data];
  xPoints = [...Array(Math.max(0, 11 - xPoints.length)).fill(null), ...xPoints];

  const tooltipFormatter = (sparklines, options, point) => {

    let date = xPoints[point.x];

    let value = 100 - point.y;

    if (date && !isNaN(value)) {
      if (value === 100) value = '> 99';
      const dateStr = moment.unix(date).format("MMM Do") 
      return `${dateStr}: <span style='font-weight: bold;'>${value}</span>`;
    } else {
      return '-';
    }

  };


  return <Sparkline
    key={uuidv4()}
    data={data}
    min={0}
    max={100}
    width='110px'
    height='36px'
    type='line'
    tooltipFormatter={tooltipFormatter}
    showPlaceholder={false}
  />

}