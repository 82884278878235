import './SignUp.scss';

import React, { useState, useEffect } from 'react'
import Modal from '../../features/keywords/react/Modal/Modal';
import SignUpForm from './SignUpForm';
import { createSetupIntent, subscribe, createUserWithSubscription, activate } from './backend';
import { subscribe as subscribeToEvent, unsubscribe as unsubscribeFromEvent } from '../../shared/events';
import ActivateSubscription from './ActivateSubscription';
import { getNextPlanForLimits, getUserTier } from '../../shared/plans';
import posthogClient from '../../shared/posthogClient';
import UpsellContactSupport from './UpsellContactSupport';
import UpgradeRankingLists from './UpgradeRankingLists';

const getSaleConfigValues = (user, eventDetail = {}) => {

  const tests = [

    // Logged out users
    (user, eventDetail) => !user.isZuulUser && ['fresh', 'bronze_trial_plan', 'shown_10dollar_modal'],

    // Expired users with a valid card
    (user, eventDetail) => user.isExpiredUser && user.hasValidCard && ['expired_valid_card', 'bronze_monthly', 'shown_expired_with_valid_card_modal'],

    // Expired users with an invalid card
    (user, eventDetail) => user.isExpiredUser && !user.hasValidCard && ['expired_invalid_card', 'bronze_monthly', 'shown_expired_with_invalid_card_modal'],

    // Upselling users who are hitting limits or curious about upgrading
    (user, eventDetail) => {
      const { limit, requested, browsing } = eventDetail;

      if (limit && limit.match(/ranking/)) {
        const statePrefix = browsing ? 'curious_about' : 'out_of';
        let eventName = 'shown_ranking_upgrade_modal';
        
        let userState = `${statePrefix}_${limit}`;
        
        // Get the next plan up with the number of lists they requested.
        let priceId = getNextPlanForLimits(user, limit, requested);
        
        // If they're gold, there's nowhere they can go - set a userState that will show them the 'contact support' modal
        if (!priceId && getUserTier(user) === 'gold') {
          userState = 'curious_about_even_more_ranking_lists';
          eventName = 'gold_wants_more_lists_shown_contact_support_modal';
        };

        // Handle an edge case: if user has downgraded from gold but still has the gold limit number of lists, priceId will be null because 'requested' would be one more than the max of any plan.
        // So we need to re-request a priceId with their current number of lists, which should be 'requested' minus one.
        if (!priceId && ['bronze', 'silver', 'hold'].includes(getUserTier(user))) {
          priceId = getNextPlanForLimits(user, limit, (requested - 1));
        }

        return [userState, priceId, eventName, null];
      }

    }

  ];

  for (const test of tests) {
    const [ userState, priceId, eventName ] = test(user, eventDetail) || [];
    if (userState) return { userState, eventName, priceId };
  }

  throw `Could not determine the user's state!`;

};

export default function SignUp({user, stripe, countryCode}) {

  const [Shown, SetShown] = useState(false);
  const [Processing, SetProcessing] = useState(false);
  const [Success, SetSuccess] = useState(false);
  const [SetupIntent, SetSetupIntent] = useState(false);
  const [SaleConfig, SetSaleConfig] = useState({});

  const onEvent = e => {
    const { eventName, ...saleConfig } = getSaleConfigValues(user, e.detail);
    posthogClient.capture(eventName);
    SetSaleConfig(saleConfig);
    openModal();
  };

  useEffect(() => {

    subscribeToEvent("clickedUpgrade", onEvent);
    subscribeToEvent("triggerUpsell", onEvent);

    return () => {
      unsubscribeFromEvent("clickedUpgrade", () => SetUser(null));
      unsubscribeFromEvent("triggerUpsell");
    }
  }, []);

  const setNotProcessing = () => SetProcessing(false);

  const handleSetProcessing = value => SetProcessing(value);

  const buildLoginUrl = (response, user) => {
    let email = encodeURIComponent(user.email);
    return `${__ZUUL_URL}/consumer_search?user_email=${email}&user_token=${response.user.token}`;
  }

  const handleCreateSetupIntent = token => new Promise((resolve, reject) => {

    // SetupIntent might already be stored. If so, return it.
    if (SetupIntent) return resolve(SetupIntent);

    // Otherwise create a new SetupIntent.
    SetProcessing(true);
    createSetupIntent(token)
      .then(response => {
        SetSetupIntent(response.intent);
        resolve(response.intent);
      })
      .catch(error => {
        console.error("SetupIntent not generated.", error);
        reject(error);
      })
      .finally(setNotProcessing)
  });

  const authenticateCard = (setupIntent, card, firstName, lastName) => new Promise((resolve, reject) => {
    stripe.confirmCardSetup(setupIntent.client_secret, {
      payment_method: {
        card,
        billing_details: {
          name: `${firstName} ${lastName}`
        }
      }
    }).then(result => {
      if (result.error) {
        const error = { card_number: result.error.message }
        reject(error);
      } else {
        SetSetupIntent(result.setupIntent);
        resolve(result.setupIntent);
      }
    });
  })

  const onSubscribeWithCard = (response, formUser) => {
    return user.isZuulUser ? {} : { loginUrl: buildLoginUrl(response, formUser) };
  }

  const handleSubmit = (setupIntentArg, formData, priceId, card) => {

    return new Promise((resolve, reject) => {
      if (!setupIntentArg) setupIntentArg = SetupIntent;
      if (!setupIntentArg) reject({error: 'No setup intent'});

      SetProcessing(true);

      return authenticateCard(setupIntentArg, card, formData.first_name, formData.last_name)
        .then(setupIntent => {
          let payload = {...formData, intent_id: setupIntent.id, plan_code: priceId};
          const createSubFn = user.isZuulUser ? subscribe : createUserWithSubscription;
          createSubFn(payload)
            .then(response => {
              SetSuccess(true);
              resolve(onSubscribeWithCard(response, formData));
            })
            .catch(error => {
              SetSetupIntent(null);
              reject(error);
            })
            .finally(setNotProcessing);
        })
        .catch(err => {
          reject(err);
          setNotProcessing();
        });
    })
  }

  const handleActivate = (priceId) => {
    return new Promise((resolve, reject) => {
      SetProcessing(true);
      activate({plan_code: priceId})
        .then(response => {
          SetSuccess(true);
          resolve(response);
        })
        .catch(reject)
        .finally(setNotProcessing);
    });
  }

  const openModal = () => {
    SetShown(true);
  }

  const closeModal = () => {
    SetShown(false);
  }

  return (
    <Modal show={Shown} dismissable={false} onClose={closeModal}>
      { ['curious_about_even_more_ranking_lists'].includes(SaleConfig.userState) && (
        <UpsellContactSupport />
      ) }
      { ['expired_valid_card', 'curious_about_ranking_lists', 'out_of_ranking_lists'].includes(SaleConfig.userState) && (
        <ActivateSubscription
          priceId={SaleConfig.priceId}
          userState={SaleConfig.userState}
          onActivate={handleActivate}
          processing={Processing}
          success={Success}
          user={user}
        />
      ) }
      { ['fresh', 'expired_invalid_card'].includes(SaleConfig.userState) && (
        <SignUpForm
          countryCode={countryCode}
          priceId={SaleConfig.priceId}
          couponId={SaleConfig.couponId}
          userState={SaleConfig.userState}
          onSubmit={handleSubmit}
          createSetupIntent={handleCreateSetupIntent}
          setupIntent={SetupIntent}
          processing={Processing}
          onSetProcessing={handleSetProcessing}
          success={Success}
          user={user}
          stripe={stripe}
        />
      ) }
    </Modal>
  )

}