import React, { useState, useEffect, createRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import classNames from 'classnames';
import Form from '../forms/Form';
import TextField from '../forms/TextField';
import Checkbox from '../forms/Checkbox';
import SignupCountrySelect from '../forms/SignupCountrySelect';
import { minLength, validEmail, required } from '../forms/validations';
import PlanSalesSummary from './PlanSalesSummary';
import SignupFormProgressIndicator from './SignupFormProgressIndicator';
import StripeCardField from './StripeCardField';
import { getSalesText } from '../../shared/plans';
import logo from '../../images/wordtracker-logo-black.png';
import poweredByStripe from '../../images/stripe-mark-blurple.svg';
import posthogClient from '../../shared/posthogClient';

import visa from '../../images/visa.svg';
import amex from '../../images/amex.svg';
import mastercard from '../../images/mastercard.svg';
import discover from '../../images/discover.svg';

export default function SignUpForm({user, createSetupIntent, stripe, processing, onSetProcessing, success, onSubmit, priceId, couponId, userState, countryCode, setupIntent}) {

  const stageNames = ["details", "billing", "done"];

  const initialFormValues = {
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    newsletter: false,
    country: 'US'
  }

  if (countryCode) {
    initialFormValues.newsletter = countryCode === 'US';
    initialFormValues.country = countryCode;
  }

  const salesText = getSalesText(priceId, userState, couponId);

  const choosePlansLink = user.isZuulUser ? `${__ZUUL_URL}/account/subscription/edit` : `${__ZUUL_URL}/sign-up`;

  const firstStage = userState === 'expired' ? 2 : 1;
  const [ Card, SetCard ] = useState(null);
  const [ Progress, SetProgress ] = useState(firstStage);
  const [ ActiveStage, SetActiveStage ] = useState(firstStage);
  const [ StageName, SetStageName ] = useState(stageNames[firstStage - 1]);
  const [ ReCaptchaToken, SetReCaptchaToken ] = useState(null);
  const [ FormData, SetFormData ] = useState(initialFormValues);
  const [ Errors, SetErrors ] = useState({});

  const handleChangeField = e => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    SetFormData({
      ...FormData,
      [e.target.name]: value
    });
    // SetErrors({
    //   ...Errors,
    //   [e.target.name]: null
    // });
  }

  const handleBackendError = (errors) => {
    console.error(errors);
    SetErrors(errors);
    if (errors.email || errors.password) {
      goToStage(1);
    }
  }
  
  const handleErrorStateChange = (e, error) => {
    if (error) console.error(error);
    const fieldName = [e.target.name];
    let _errors = { ...Errors, [fieldName]: error };
    _errors = Object.fromEntries(Object.entries(_errors).filter(([key, value]) => value !== null));
    SetErrors(_errors);
  }

  const handleReCaptchaErrored = (error) => {
    console.error("Errored");
    console.error(error);
  }

  const handleReCaptchaExpired = () => {
    console.log("ReCaptcha Expired");
    SetReCaptchaToken(null);
  }

  const handleOnCard = card => {
    SetCard(card);
  }

  const goToStage = (stage, advanceProgress = false) => {

    onSetProcessing(false);

    const lastStageNum = stageNames.length + 1; 

    // Redundant setting stage if it's already correct
    if (stage === ActiveStage) return false;

    // Last stage is the 'done' page - can't go back.
    if (ActiveStage === lastStageNum) return false;

    // Can't go past last stage or before first stage
    if (stage > lastStageNum || stage < firstStage) return false;

    // Can't advance past stages you've not completed
    if (!advanceProgress && stage > Progress) return false;

    if (stage > Progress) SetProgress(stage);

    SetActiveStage(stage);

    SetStageName(stageNames[stage - 1]);

  };

  const getReCaptchaToken = () => new Promise(resolve => {
    captchaRef.current.reset();
    captchaRef.current.executeAsync().then(resolve);
  });

  const next = () => {
    onSetProcessing(true);
    getReCaptchaToken()
      .then(token => {
        SetReCaptchaToken(token)
        createSetupIntent(token)
          .then(res => {
            posthogClient.capture('signup_modal_stage_two');
            return goToStage(2, true)
          })
          .catch(console.error);
      })
      .catch(console.error);
  }

  const preIntend = () => new Promise((resolve, reject) => {
    // Recaptcha and intent normally done on submit details.
    // But if user is already logged in, we're not submitting details, so go get them.
    if (ReCaptchaToken && setupIntent) return resolve();
    getReCaptchaToken()
      .then(token => {
        SetReCaptchaToken(token)
        createSetupIntent(token).then(resolve);
      })
      .catch(console.error);
  });

  const handleOnKeypressPassword = (e) => {
    // if (e.code === 'Enter') {
    //   e.target.blur();
    //   if (!isDisabled) {
    //     next();
    //   }
    // }
  }

  const handleSubmit = () => {
    preIntend().then(setupIntent => {
      onSubmit(setupIntent, FormData, priceId, Card)
      .then(response => {
        goToStage(3, true);
        posthogClient.capture('signup_modal_complete');
        const redirectUrl = response.loginUrl ? response.loginUrl : window.location.href;
        setTimeout(() => window.location.href = redirectUrl, 1200);
      })
      .catch(handleBackendError);
    })
  }

  const emailValidations = [ required(), validEmail() ]
  const passwordValidations = [ minLength(6) ]
  const nameValidations = [ required() ]
  const captchaRef = createRef();

  const filledOut = (FormData.email.length > 0 && FormData.password.length > 0);
  const isDisabled = Errors.email || Errors.password || !filledOut || processing;

  const renderDetailsForm = () => {

    posthogClient.capture('signup_modal_stage_one');

    return (

      <div className='signup-form-area details fade-in' style={{display: StageName === 'details' ? 'block' : 'none'}}>

        {salesText.subtitle && <p className='subtitle'>{salesText.subtitle}</p>}

        <div className='two-blocks'>

          <div className='signup-block stripe-form-area'>

            {salesText.titleDetails && <p className='sales-text'>{salesText.titleDetails}</p> }

            <div className="form-row">
              <TextField
                name='email'
                type='text'
                label='Email address'
                klassname='email'
                initialValue={FormData.email}
                onErrorStateChange={handleErrorStateChange}
                onChange={handleChangeField}
                error={Errors.email}
                validations={emailValidations}
                placeholder='name@example.com'
              />
            </div>

            <div className="form-row">
              <TextField
                name='password'
                type='password'
                label='Password'
                klassname='password'
                initialValue={FormData.password}
                onErrorStateChange={handleErrorStateChange}
                onChange={handleChangeField}
                onKeyPress={handleOnKeypressPassword}
                validations={passwordValidations}
                error={Errors.password}
              />
            </div>

            <div className="form-row checkbox-row">
              <Checkbox
                name='newsletter'
                label={<span>Please keep in touch with the latest SEO updates</span>}
                klassname='newsletter'
                initialValue={FormData.newsletter}
                onErrorStateChange={handleErrorStateChange}
                onChange={handleChangeField}
                error={Errors.newsletter}
              />
            </div>

            <button
              className='subscribe-standard standard'
              type='button'
              disabled={isDisabled}
              onClick={next}
            >{processing ? 'Processing...' : salesText.buttonDetails}</button>

            {salesText.buttonDetailsHint && <span className='button-hint'>{salesText.buttonDetailsHint}</span>}

          </div>

          <PlanSalesSummary priceId={priceId} user={user} couponId={couponId} country={FormData.country} />

        </div>

        { priceId != 'lannister_1dollargold_special' && <span className='continue-button-note switch-to-monthly nolink'>Need more results or territories? <a href={choosePlansLink} target='_self'>Check out all our plans here</a>.</span> }

      </div>
    )
  }

  const renderBillingForm = () => {

    const isBillingDisabled = !FormData.first_name || !FormData.last_name;

    return (<div className='signup-form-area billing fade-in' style={{display: StageName === 'billing' ? 'block' : 'none'}}>

      {salesText.subtitle && <p className='subtitle'>{salesText.subtitle}</p>}

      <div className='two-blocks'>

        <div className='signup-block stripe-form-area'>

          {salesText.titleBilling && <p className='sales-text'>{salesText.titleBilling}</p> }

          <div className='form-row'>
            <label className={`number ${classNames({"has_error": Errors.card_number})}`}>
              Card details&nbsp;
              { Errors.card_number && <span className='error'>{Errors.card_number}</span> }
              <StripeCardField
                card={Card}
                stripe={stripe}
                onCard={handleOnCard}/>
            </label>
          </div>

          <div className='form-row dual-row'>
            
            <TextField
              name='first_name'
              type='text'
              label='First name'
              klassname='first-name'
              initialValue={FormData.first_name}
              onErrorStateChange={handleErrorStateChange}
              onChange={handleChangeField}
              error={Errors.first_name}
              validations={nameValidations}
            />

            <TextField
              name='last_name'
              type='text'
              label='Last name'
              klassname='last-name'
              initialValue={FormData.last_name}
              onErrorStateChange={handleErrorStateChange}
              onChange={handleChangeField}
              error={Errors.last_name}
              validations={nameValidations}
            />

          </div>

          <div className='stripe-trust'>
            <h5>
              <i className="fa-solid fa-lock"></i>
              Guaranteed <strong>safe and secure</strong> checkout
            </h5>
            <div className="trust-icons">
              <img className='powered-by-stripe' src={poweredByStripe} alt='Powered By Stripe' />
              <img className='card-icon' src={visa} alt='Visa card icon' />
              <img className='card-icon' src={mastercard} alt='Mastercard card icon' />
              <img className='card-icon' src={amex} alt='Amex card icon' />
              <img className='card-icon' src={discover} alt='Discover card icon' />
            </div>
          </div>

          {/* <div className="form-row country-row">
            <SignupCountrySelect
              initialValue={FormData.country}
              onChange={handleChangeField}
              error={Errors.country}
            />
          </div> */}

          <div className='form-row'>
            <button
              onClick={handleSubmit}
              className={`subscribe-standard standard ${classNames({success})}`}
              disabled={processing || success || isBillingDisabled }
            >{ success ? "Success!" : processing ? "Processing..." : salesText.buttonBilling }</button>
            {salesText.buttonHint && <span className='button-hint'>{salesText.buttonHint}</span>}
          
          </div>

        </div>

        <PlanSalesSummary priceId={priceId} user={user} couponId={couponId} country={FormData.country} />

      </div>

      { priceId != 'lannister_1dollargold_special' && <span className='continue-button-note switch-to-monthly nolink'>Need more results or territories? <a href={choosePlansLink} target='_self'>Check out all our plans here</a>.</span> }

    </div>
  )}

  const renderDone = () => (
    <div className='signup-form-area done fade-in' style={{display: StageName === 'done' ? 'block' : 'none'}}>
      <div className='big-tick'></div>
      <h2>Sign-up complete.</h2>
      {salesText.doneHtml}
    </div>
  )

  return (
    <div id='signup-form'>
      <div className='padlock-motif'></div>

      { StageName != 'done' && <>
        <img className='wt-logo-in-form' src={logo} alt='Wordtracker logo' />
        <h4>
          <i className="fa-regular fa-square-check"></i>
          {salesText.title}
        </h4>

        {salesText.priceMark}

      </>
      }

      <Form name='stripeForm' id='stripe-form-signup'>

        <ReCAPTCHA
          size="invisible"
          sitekey={__WORDTRACKER_INVISIBLE_RECAPTCHA_SITE_KEY}
          ref={captchaRef}
          onErrored={handleReCaptchaErrored}
          onExpired={handleReCaptchaExpired}
        />

        <div className='signup-form-stage-holder'>

          { renderDetailsForm() }

          { renderBillingForm() }

          { renderDone() }

          <input type="hidden" name="stripe-token" />

        </div>

      </Form>

      {/* { StageName != 'done' && salesText.bundleOffer} */}

      { userState != 'expired' && <SignupFormProgressIndicator
        onClickNode={goToStage}
        progress={Progress}
        activeStage={ActiveStage}
      /> } 

    </div>
  )
}