const SLIDER_RESOLUTION = 400;
const ROUND_NUMBERS = [1, 5, 10, 25, 50, 100, 250, 500, 1000, 2500, 5000, 10000, 25000, 50000, 100000, 250000, 500000, 1000000]; // Array of nice round numbers

const calculateStep = (availableMin, availableMax) => {
  const range = availableMax - availableMin;
  const desiredStep = range / SLIDER_RESOLUTION;

  let closestStep = ROUND_NUMBERS[0];

  for (let i = 0; i < ROUND_NUMBERS.length; i++) {
    if (ROUND_NUMBERS[i] <= desiredStep) {
      closestStep = ROUND_NUMBERS[i];
    } else {
      break;
    }
  }

  return closestStep;
}

const roundDownToStep = (availableMin, step) => {
  return Math.floor(availableMin / step) * step;
}

const roundUpToStep = (availableMax, step) => {
  return Math.ceil(availableMax / step) * step;
}

export default (availableMin, availableMax) => {
  const step = calculateStep(availableMin, availableMax);
  console.log('step is', step);
  let _availableMin = roundDownToStep(availableMin, step);
  let _availableMax = roundUpToStep(availableMax, step);
  return {
    step,
    availableMin: _availableMin,
    availableMax: _availableMax
  }
}