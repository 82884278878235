import React, { Component } from 'react'
import PaginationControls from './PaginationControls'
import KeywordTable from './KeywordTable'

const CHANGE_METRICS = ['rank_delta', 'clicks_change_domain']

class KeywordsDisplay extends Component {

  state = {
    keywords: [], // Pre filtered results,
    sortedResults: [], // All rows, sorted
    currentResults: [], // The sorted rows for the current page
    currentPage: null,
    sortField: this.props.sortField || "volume",
    sortDescending: true,
    perPage: parseInt(this.props.perPage) > 0 ? parseInt(this.props.perPage) : 100,
    selectedItems: [],
    lastSelectedItem: null,
    isSelectAll: false,
  }

  constructor(props) {
    super(props)
    this.handleSetSorting = this.handleSetSorting.bind(this)
    this.handleSelectItem = this.handleSelectItem.bind(this)
    this.handleToggleSelectAll = this.handleToggleSelectAll.bind(this)
  }

  componentDidUpdate() {
    if (this.props.keywords && JSON.stringify(this.props.keywords) != JSON.stringify(this.state.keywords)) {
      this.updateSourceKeywords();
    }
  }

  handleSetSorting({sortDescending, sortField}) {
    if (sortField != this.state.sortField) sortDescending = true; // If changing to a new column, start with sortDescending.
    this.setState({sortField, sortDescending }, () => {
      this.updateSorting();
    });
  };

  updateSorting() {
    const { sortField, sortDescending, currentPage, perPage } = this.state;
    const sortedResults = this.state.keywords.sort((a, b) => {
      
      // Sorting for change metrics
      if (CHANGE_METRICS.includes(sortField)) {
        if (!a[sortField]) return 1; // Any results with no data should be last no matter whether ascending or descending
        return (a[sortField] > (b[sortField]))// || a.appended)
          ? (!sortDescending ? 1 : -1)
          : (sortDescending ? 1 : -1)
      }

      // Sorting for all other metrics
      if (!a[sortField] || a[sortField] < 0) {
        return 1; // Any results with no data should be last no matter whether ascending or descending
      }
      return (a[sortField] > (b[sortField] || -1))// || a.appended)
        ? (!sortDescending ? 1 : -1)
        : (sortDescending ? 1 : -1)

    })
    this.setState({ sortedResults }, () => {
      this.setPage({currentPage, perPage});
    });
    this.props.onUpdateSorting(sortField, sortDescending);
  }

  updateSourceKeywords() {
    const { keywords } = this.props;
    this.setState({ keywords }, () => {
      this.updateSorting();
    });
  }

  setPage = data => {
    const { currentPage, perPage } = data;
    const offset = (currentPage - 1) * perPage;
    const currentResults = this.state.sortedResults.slice(offset, offset + perPage);
    this.setState({ currentResults, currentPage, lastSelectedItem: null });
  }

  handleSelectItem(e, isShiftDown) {
    const { value } = e.target;
    const nextValue = this.getNextValue(value, isShiftDown);
    this.setState({ selectedItems: nextValue, lastSelectedItem: value });
    this.props.onChangeSelected(nextValue);
  }
  
  getNextValue(value, isShiftDown) {
    const { selectedItems } = this.state;
    const hasBeenSelected = !selectedItems.includes(value);
    
    if (isShiftDown) {
      const newSelectedItems = this.getNewSelectedItems(value);
      // de-dupe the array using a Set
      const selections = [...new Set([...selectedItems, ...newSelectedItems])];
      
      if (!hasBeenSelected) {
        return selections.filter(item => !newSelectedItems.includes(item));
      }
      
      return selections;
    }
    
    // if it's already in there, remove it, otherwise append it
    return selectedItems.includes(value)
    ? selectedItems.filter(item => item !== value)
    : [...selectedItems, value];
  }
  
  getNewSelectedItems(value) {
    const { lastSelectedItem, currentResults } = this.state;
    const currentSelectedIndex = currentResults.findIndex(item => item.keyword === value);
    const lastSelectedIndex = currentResults.findIndex(
      item => item.keyword === lastSelectedItem
    );
      
    return currentResults.slice(
      Math.min(lastSelectedIndex, currentSelectedIndex),
      Math.max(lastSelectedIndex, currentSelectedIndex) + 1
    ).map(item => item.keyword);
  }

  selectAllCurrent() {
    const { selectedItems } = this.state;
    const newSelectedItems = this.props.keywords.map(keyword => keyword.keyword);
    const selections = [...new Set([...selectedItems, ...newSelectedItems])];
    this.setState({selectedItems: selections});
    this.props.onChangeSelected(selections);
  }
  
  deselectAllCurrent() {
    const { selectedItems } = this.state;
    const newDeselectedItems = this.props.keywords.map(keyword => keyword.keyword);
    const selections = selectedItems.filter(item => !newDeselectedItems.includes(item));
    this.setState({selectedItems: selections});
    this.props.onChangeSelected(selections);
  }
  
  handleToggleSelectAll() {
    const isSelectAll = !this.state.isSelectAll;
    if (isSelectAll) {
      this.selectAllCurrent();
    } else {
      this.deselectAllCurrent();
    }
    this.setState({isSelectAll, lastSelectedItem: null})
  }
  
  render() {
    
    const { currentResults, perPage, sortField, sortDescending, selectedItems, sortedResults, isSelectAll } = this.state;
    const { fields, hasFilters, loading, failed, source, onClickResult, onClickSparkline, isFreeUser, onClickUnlock, serpsResults, possible } = this.props;
    
    const keywordsTableProps = {
      results: currentResults,
      onSetSorting: this.handleSetSorting,
      handleSelectItem: this.handleSelectItem,
      onToggleSelectAll: this.handleToggleSelectAll,
      totalResults,
      fields,
      hasFilters,
      loading,
      failed,
      source,
      sortField,
      sortDescending,
      onClickResult,
      onClickUnlock,
      isFreeUser,
      onClickSparkline,
      selectedItems,
      serpsResults,
      isSelectAll,
      possible
    }

    const totalResults = sortedResults ? sortedResults.length : 0;

    return (
      <>
        <KeywordTable {...keywordsTableProps} totalResults={totalResults}></KeywordTable>
        <PaginationControls
          totalRecords={totalResults}
          perPage={perPage}
          onPageChanged={this.setPage} />
      </>
    )
  }
}

export default KeywordsDisplay;
