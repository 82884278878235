import { getField } from "../shared/fields";

const roundToNearestHour = (timestamp) => {
  return Math.round((parseInt(timestamp) * 1000) / (60 * 60 * 1000)) * (60 * 60 * 1000);
}

const FIELDS = {
  default: [
    'volume',
    'competition',
    'kei',
    'cpc',
    'pct_mobile_searches',
    'pct_desktop_searches',
    'pct_searches_no_clicks',
    'pct_paid_clicks',
    'pct_organic_clicks',
    'intent',
    'clicks_domain',
    'clicks_change_domain',
    'rank',
    'rank_delta',
    'clicks',
    'impressions',
    'position',
    'ctr',
    'iaat_legacy',
    'cmp_legacy'
  ],
  ranking: [
    'rank',
    'rank_delta',
    'ranking_url',
    /rank_\d+/
  ]
}

const parseDefaultValue = value => ((value && value == "-1") || !value) ? "-" : value;

const PARSERS = {
  'default': (key, value) => ({
    heading: getField(key).heading,
    value: parseDefaultValue(value)
  }),
  '/rank_\\d+/': (key, value) => ({
    heading: `Rank on ${new Date(parseInt(key.split('_')[1])).toISOString().slice(0, 10)}`,
    value: parseDefaultValue(value)
  })
}

const buildRow = (collectionName, row) => {

  const fieldSetKey = {
    ranking: 'ranking'
  }[collectionName] || 'default';

  let fields = FIELDS[fieldSetKey];

  const values = { 'Keyword': row.keyword }

  fields.forEach(field => {

    const parser = PARSERS[field.toString()] || PARSERS.default;

    let fieldsToAdd = [field];

    // Replace with RegExp fields if RegExp
    if (field instanceof RegExp) {
      fieldsToAdd = Object.keys(row).filter(key => !!key.match(field));
    }

    fieldsToAdd.forEach(_field => {
      const { heading, value } = parser(_field, row[_field]);
      values[heading] = value;
    })

  });

  values['Country'] = row.country;
  values['Data source'] = row.source;

  return values;
}

export const buildRows = (collectionName, data) => {

  let _data = JSON.parse(JSON.stringify(data)) // Deep copy required, or weird things happen

  if (collectionName === 'ranking') {

    let rankHistory = {};

    // Loop once to get all the timestamps
    _data = _data.map(row => {

      const rank_history = row.rank_history || {};

      Object.keys(rank_history).forEach(timestamp => {

        const bucketedTimestamp = 'rank_' + roundToNearestHour(timestamp).toString();

        if (!rankHistory.hasOwnProperty(bucketedTimestamp)) {
          rankHistory[bucketedTimestamp] = [];
        }

        // Rename the timestamp to the bucketed version
        const rank = rank_history[timestamp];
        delete rank_history[timestamp];
        rank_history[bucketedTimestamp] = rank;

      });

      row.rank_history = rank_history;

      return row;

    });

    // Loop again to fill them up
    _data = _data.map(row => {

      Object.keys(rankHistory).forEach(_timestamp => {
        row[_timestamp] = row.rank_history ? row.rank_history[_timestamp] || -1 : -1;
      });

      return row;

    });

  }

  return _data.map(row => {  
    return buildRow(collectionName, row);
  });

}