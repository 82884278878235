import { getFields } from "../shared/fields";

export const $activeColumns = function($cookies, $searchSettings) {

  const COOKIE_KEYS = {
    search: '__wordtracker_activeColumns_search',
    lists: '__wordtracker_activeColumns_lists'
  }

  const DEFAULT_FIELDS = {
    search: {
      'google':      ['volume', 'competition', 'kei'],
      'amazon':      ['volume', 'competition'],
      'youtube':     ['volume', 'competition'],
      'ebay':        ['volume', 'competition'],
      'seo':         ['volume', 'competition', 'kei', 'pct_searches_no_clicks'],
      'ppc':         ['volume', 'cpc', 'intent', 'pct_paid_clicks'],
      'gsc':         ['volume', 'impressions', 'clicks', 'ctr', 'position']
    },
    list: {
      'default':     ['volume', 'competition', 'cpc', 'kei'],
      'seo':         ['volume', 'competition', 'kei', 'pct_searches_no_clicks'],
      'ppc':         ['volume', 'cpc', 'intent', 'pct_paid_clicks'],
      'gsc':         ['volume', 'competition', 'clicks', 'impressions', 'ctr', 'position']
    },
    all:             ['volume', 'competition', 'cpc', 'iaat_legacy', 'kei', 'intent', 'clicks', 'ctr', 'position', 'impressions'],
    domain_keywords_by_clicks: ['clicks_domain', 'clicks_change_domain', 'rank', 'rank_delta', 'volume', 'competition'],
    domain_keywords_by_clicks_change: ['clicks_domain', 'clicks_change_domain', 'rank', 'rank_delta', 'volume', 'competition'],
    domain_keywords_by_volume: ['volume', 'competition', 'kei', 'pct_searches_no_clicks'],
    ranking:         ['rank', 'rank_delta', 'rank_history', 'ranking_url'],
    vanilla:         ['volume']
  };
  
  const AVAILABLE_FIELDS = {
    'google': ['volume', 'cpc', 'competition', 'kei', 'intent', 'pct_mobile_searches', 'pct_desktop_searches', 'pct_paid_clicks', 'pct_organic_clicks', 'pct_searches_no_clicks', 'clicks', 'ctr', 'position', 'impressions', 'cmp_legacy', 'iaat_legacy', 'intent_legacy'],
    'amazon': ['volume', 'competition', 'iaat_legacy'],
    'youtube': ['volume', 'competition', 'iaat_legacy'],
    'ebay': ['volume', 'competition', 'iaat_legacy'],
    'domain_keywords_by_clicks': ['volume', 'clicks_domain', 'clicks_change_domain', 'rank', 'rank_delta', 'total_monthly_clicks', 'competition', 'kei', 'pct_searches_no_clicks'],
    'domain_keywords_by_clicks_change': ['volume', 'clicks_domain', 'clicks_change_domain', 'rank', 'rank_delta', 'total_monthly_clicks', 'competition', 'kei', 'pct_searches_no_clicks'],
    'domain_keywords_by_volume': ['volume', 'cpc', 'competition', 'kei', 'intent', 'pct_mobile_searches', 'pct_desktop_searches', 'pct_paid_clicks', 'pct_organic_clicks', 'pct_searches_no_clicks', 'clicks', 'ctr', 'position', 'impressions', 'cmp_legacy', 'iaat_legacy', 'intent_legacy'],
    'ranking': ['rank', 'rank_delta', 'ranking_url'],
  }

  let cols = {
    active: {
      list: [],
      search: [],
      domain_keywords_by_clicks: [],
      domain_keywords_by_clicks_change: [],
      domain_keywords_by_volume: [],
      ranking: [],
    },
    activeColView: {
      list: null,
      search: 'seo',
      domain_keywords_by_clicks: 'domain_keywords_by_clicks',
      domain_keywords_by_clicks_change: 'domain_keywords_by_clicks_change',
      domain_keywords_by_volume: 'domain_keywords_by_volume',
      ranking: 'ranking'
    }
  }

  cols.get = (keys) => {
    return getFields(keys);
  };

  cols.load = (keys, location, doUpdateCookies) => {
    angular.copy(cols.get(keys, location), cols.active[location]);
    if (doUpdateCookies) cols.updateCookies(location);
  };

  cols.updateCookies = (location) => {
    const cookieKey = COOKIE_KEYS[location]
    let cookie = $cookies.getObject(cookieKey) || {};
    let source = $searchSettings.source;
    cookie[source] = cols.active[location].map(c => c.key);
    $cookies.putObject(cookieKey, cookie);
  };

  cols.isChecked = (col, location) => {
    return !!_.findWhere(cols.active[location], {key: col.key});
  };

  cols.toggleCheck = (col, location) => {
    cols.activeColView[location] = '';
    let activeKeys = cols.active[location].map(c => c.key);
    let newKeys = (cols.isChecked(col, location)) ? _.without(activeKeys, col.key) : _.union(activeKeys, [col.key]);
    cols.load(newKeys, location, true);
  };

  cols.loadSeo = (location) => {
    cols.load(DEFAULT_FIELDS[location].seo, location);
    cols.activeColView[location] = 'seo';
  };

  cols.loadList = () => {
    cols.load(DEFAULT_FIELDS.list.default, 'list');
    cols.activeColView[location] = null;
  };

  cols.loadVanilla = (location) => {
    cols.load(DEFAULT_FIELDS[location].vanilla, location);
    cols.activeColView[location] = 'vanilla';
  };

  cols.loadPpc = (location) => {
    cols.load(DEFAULT_FIELDS[location].ppc, location);
    cols.activeColView[location] = 'ppc';
  };

  cols.loadGsc = (location) => {
    cols.load(DEFAULT_FIELDS[location].gsc, location);
    cols.activeColView[location] = 'gsc';
  };

  cols.loadDefault = (source, location) => {
    cols.loadSeo(location);
  };

  cols.loadDomain = () => {
    cols.load(DEFAULT_FIELDS.domain_keywords_by_clicks, 'domain_keywords_by_clicks');
    cols.load(DEFAULT_FIELDS.domain_keywords_by_clicks_change, 'domain_keywords_by_clicks_change');
    cols.load(DEFAULT_FIELDS.domain_keywords_by_volume, 'domain_keywords_by_volume');
    cols.activeColView['domain_keywords_by_clicks'] = 'domain_keywords_by_clicks';
    cols.activeColView['domain_keywords_by_clicks_change'] = 'domain_keywords_by_clicks_change';
    cols.activeColView['domain_keywords_by_volume'] = 'domain_keywords_by_volume';
  };

  cols.loadRanking = () => {
    cols.load(DEFAULT_FIELDS.ranking, 'ranking');
    cols.activeColView['ranking'] = 'ranking';
  }

  cols.loadAll = (location) => {
    cols.load(DEFAULT_FIELDS.all, location);
  };

  cols.viewApplies = (source, location) => {
    const available = AVAILABLE_FIELDS[source]
    return cols.active[location].every(field => available.includes(field.key))
  };

  cols.onSearch = () => {
    let source = $searchSettings.source;
    let cookies = $cookies.getObject(COOKIE_KEYS.search);
    if (cols.activeColView.search) {
      if (cols.viewApplies(source, 'search')) {
        // Do nothing
      } else {
        cols.loadDefault(source, 'search');
      }
    } else {
      let cached = cookies && cookies[source];
      const keysToLoad = (!!cached) ? cached : cols.loadDefault(source, 'search');
      cols.load(keysToLoad, 'search');
    }
  };

  return cols;

};

$activeColumns.$inject = ['$cookies', '$searchSettings'];
